@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


:root {
    --scroll-size: 10px;
    --scroll-radius: 20px;
    --scroll-track: rgba(255, 255, 255, 0);
    --scroll-thumb: linear-gradient(45deg, #850091, #a84fb0);
    --ion-color-sdnd: #850091
  }

  .ion-color-sdnd {
    --ion-color-base: var(--ion-color-sdnd);
  }  
  
body {
  font-family: 'Roboto', sans-serif;
  background-color: #EFDDDD;
  background-image: linear-gradient(rgba(33, 30, 197, 0.37), rgba(0, 0, 0, 0.74)), url("../public/img/background.webp");
  background-attachment: fixed;
  background-size: cover;
}

.contact{
    border-radius: 30px;
    background-image: 
    linear-gradient(rgba(127, 25, 173, 0.411), rgba(63, 4, 102, 0.74)),
          url("../public/img/contact-background.webp")
}

.contactButton{
    background-color: #850091;
}

.navButtonBack{
    background-color: #850091;
}

.generalColor{
    color: #850091;
}

.home{
  /* background-image: linear-gradient(rgba(33, 30, 197, 0.37), rgba(0, 0, 0, 0.74)), url("../public/img/testing1.png"); */
  background-size: cover;
}

.main_container{
  border-radius: 50px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow:  10px 10px 20px #bebebe,
             -10px -10px 20px #ffffff;
}

.main_page_container{
  min-height: 100vh;
}

.main_page_contact_comments_container{
  background-color: #fce2dba9;
}

.main_page_contact_comments_container_aboutme{
  background-color: #80489C;
}

/* --  */

.navigation
{
    position: relative;
    width: 470px;
    height: 70px;
    background: #eaeaea;
    align-items: center;
    border-radius: 10px;
}
.navigation ul
{
    width: 420px;
}
.navigation ul li
{
    width: 70px;
    height: 70px;
}
.navigation ul li a .icon
{
    line-height: 75px;
    transition: 0.5s;
    color: #222327;
}
/* Hover Animation */
.navigation ul li.active a .icon
{
    transform: translateY(-32px);
}
.navigation ul li a .text
{
    
    color:#3c5aa6;
    transition: 0.5s;
    transform: translateY(20px);
}
.navigation ul li.active a .text
{
    opacity: 1;
    transform: translateY(10px);
}
.indicator
{
    top: -50%;
    position: absolute;
    width: 70px;
    height: 70px;
    background:  #f705ff;
    border-radius: 50%;
    border: 6px solid #000;
    transition: 0.5s;
}
/* Blob in indicator */
.indicator::before
{
    content: '';
    position: absolute;
    top: 50%;
    left: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius:20px ;
    box-shadow: 0px -10px 0 0 #000;
}
.indicator::after
{
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius:20px ;
    box-shadow: -1px -10px 0 0 #000;
}

/* Ciecle botton animation */
.navigation ul li:nth-child(1).active ~ .indicator
{
    transform: translateX(calc(70px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator
{
    transform: translateX(calc(70px * 1 ));
}
.navigation ul li:nth-child(3).active ~ .indicator
{
    transform: translateX(calc(70px * 2));
}
.navigation ul li:nth-child(4).active ~ .indicator
{
    transform: translateX(calc(70px * 3));
}
.navigation ul li:nth-child(5).active ~ .indicator
{
    transform: translateX(calc(70px * 4));
}
.navigation ul li:nth-child(6).active ~ .indicator
{
    transform: translateX(calc(70px * 5));
}

/* --Footer desktop  */
.footerIcons {
    color: #000000;
}

/* Fonts  */
.aboutme{
    /* background-image: url("../public/img/pexels-jess-bailey-designs-1007023.jpg"); */
    background-size: cover;
}



.about_me_title {
    /* font-family: 'Tangerine', cursive; */
    font-family: 'Great Vibes', cursive;
}
.about_me_container {
    background-image: linear-gradient(rgba(185, 185, 185, 0.411), rgba(151, 150, 151, 0.74)),url("../public/img/about-me-background.webp");
    background-size: cover;
}

.service_title{
    font-family: 'Montserrat', sans-serif;
}

.contactFormIcons {
    color: #f0f0f0;
}

body::-webkit-scrollbar {
    width: var(--scroll-size, 10px);
    height: var(--scroll-size, 10px);
  }
  body::-webkit-scrollbar-track {
    background-color: var(--scroll-track, transparent);
    border-radius: var(--scroll-track-radius, var(--scroll-radius));
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb-color, grey);
    background-image: var(--scroll-thumb, none);
    border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
  }

  .whatsapp_bot{
    color: green;
  }

  .hover\:flip-and-move {
    transition: transform 1s;
  }
  
  .hover\:flip-and-move:hover {
    transform: perspective(1000px) rotateY(180deg) translateY(-20px);
    animation-delay: 1000ms;
    transform: perspective(1000px) rotateY(359deg) translateY(0);
  }

